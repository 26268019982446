import { lazy } from "react";

import {
  ISManagePaths,
  ISProfileRolesPaths,
  ISSetupPaths,
  ISTutoringPaths,
  RedirectPaths,
} from "../../utils/const";

// MANAGE
const tutors = [
  {
    path: `${ISManagePaths.TUTORS}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/view_tutor")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.TUTORS}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/list_tutors")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.TUTORS}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/edit_tutor")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.TUTORS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/add_tutor")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const parents = [
  {
    path: `${ISManagePaths.PARENTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/list_parents")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.PARENTS}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/view_parent")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.PARENTS}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/edit_parent")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.PARENTS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/add_parent")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const students = [
  {
    path: `${ISManagePaths.STUDENTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/list_students")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.STUDENTS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/add_student")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.STUDENTS}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/view_student")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.STUDENTS}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/edit_student")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const matching = [
  {
    path: `${ISManagePaths.MATCHING}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/matching/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const establishments = [
  {
    path: `${ISManagePaths.ESTABLISHMENTS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/manage/establishment/list_establishment/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.ESTABLISHMENTS}/add`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/manage/establishment/add_establishment/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.ESTABLISHMENTS}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/manage/establishment/view_establishment/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.ESTABLISHMENTS}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/manage/establishment/edit_establishment/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const vsc = [
  {
    path: `${ISManagePaths.VSC}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/vsc/list_vsc/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.VSC}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/vsc/add_vsc/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.VSC}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/vsc/view_vsc/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.VSC}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/vsc/edit_vsc/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

// TUTORING
const sessions = [
  {
    path: `${ISTutoringPaths.SESSIONS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/tutoring/sessions/list_sessions/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SESSIONS}/add`,
    component: lazy(
      () =>
        import("../../view/InternalSystem/tutoring/sessions/add_session/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SESSIONS}/view`,
    component: lazy(
      () =>
        import("../../view/InternalSystem/tutoring/sessions/view_session/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SESSIONS}/edit`,
    component: lazy(
      () =>
        import("../../view/InternalSystem/tutoring/sessions/edit_session/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const cr_reports = [
  {
    path: `${ISTutoringPaths.CR_REPORTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/cr-reports/list_cr")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.CR_REPORTS}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/cr-reports/view_cr")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.CR_REPORTS}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/cr-reports/edit_cr")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const sc_reports = [
  {
    path: `${ISTutoringPaths.SC_REPORTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/sc-reports/list_sc")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SC_REPORTS}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/sc-reports/view_sc")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SC_REPORTS}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/sc-reports/edit_sc")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

// SETUP
const scholar_year = [
  {
    path: `${ISSetupPaths.SCHOLAR_YEAR}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/scholar_year/list_scholar_years/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SCHOLAR_YEAR}/add`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/scholar_year/add_scholar_year/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SCHOLAR_YEAR}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/scholar_year/view_scholar_year/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SCHOLAR_YEAR}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/scholar_year/edit_scholar_year/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const school_zones = [
  {
    path: `${ISSetupPaths.SCHOOL_ZONES}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/school_zones/list_school_zones/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SCHOOL_ZONES}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/school_zones/view_school_zone/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SCHOOL_ZONES}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/school_zones/edit_school_zone/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const public_holidays = [
  {
    path: `${ISSetupPaths.PUBLIC_HOLIDAYS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/public_holidays/list_public_holidays/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.PUBLIC_HOLIDAYS}/add`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/public_holidays/add_public_holiday/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.PUBLIC_HOLIDAYS}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/public_holidays/view_public_holiday/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.PUBLIC_HOLIDAYS}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/public_holidays/edit_public_holiday/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const availability = [
  {
    path: `${ISSetupPaths.AVAILABILITY}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/availability/list_availabilities/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.AVAILABILITY}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/availability/view_availability/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.AVAILABILITY}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/setup/availability/edit_availability/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const sectors = [
  {
    path: `${ISSetupPaths.SECTORS}`,
    component: lazy(
      () => import("../../view/InternalSystem/setup/sectors/list_sector/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SECTORS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/setup/sectors/add_sector/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SECTORS}/view`,
    component: lazy(
      () => import("../../view/InternalSystem/setup/sectors/view_sector/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISSetupPaths.SECTORS}/edit`,
    component: lazy(
      () => import("../../view/InternalSystem/setup/sectors/edit_sector/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

// PROFILES AND ROLES
const administrator = [
  {
    path: `${ISProfileRolesPaths.ADMINISTRATORS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/administrators/list_administrators/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.ADMINISTRATORS}/add`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/administrators/add_administrator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.ADMINISTRATORS}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/administrators/view_administrator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.ADMINISTRATORS}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/administrators/edit_administrator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const manager = [
  {
    path: `${ISProfileRolesPaths.MANAGERS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/managers/list_manager/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.MANAGERS}/add`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/managers/add_manager/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.MANAGERS}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/managers/view_manager/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.MANAGERS}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/managers/edit_manager/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const coordinator = [
  {
    path: `${ISProfileRolesPaths.COORDINATORS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/coordinators/list_coordinator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.COORDINATORS}/add`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/coordinators/add_coordinator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.COORDINATORS}/view`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/coordinators/view_coordinator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISProfileRolesPaths.COORDINATORS}/edit`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/profiles-roles/coordinators/edit_coordinator/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const AdminPages = [
  {
    path: `/`,
    component: lazy(() => import("../../view/InternalSystem/index")),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },

  // MANAGE
  ...tutors,
  ...parents,
  ...students,
  ...matching,
  ...establishments,

  // TUTORING
  ...sessions,
  ...cr_reports,
  ...sc_reports,

  // SETUP
  ...scholar_year,
  ...school_zones,
  ...public_holidays,
  ...availability,
  ...sectors,

  // PROFILES AND ROLES
  ...administrator,
  ...manager,
  ...coordinator,
  ...vsc,
];

export default AdminPages;
