import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import ScrollTop from "./components/scroll-to-top";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { UserCreatedBy, UserRoles, UserStatus } from "../utils/const";
import useLocalStorage from "../hooks/useLocalStorage";
import { getUserDetailsById } from "../helpers/api";

const { Content } = Layout;

export default function VerticalLayout(props: any) {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const [redirectToOnBoarding, setRedirectToOnBoarding] =
    useState<boolean>(false);

  const [user] = useLocalStorage("user", {});
  useEffect(() => {
    const checkRedirects = async () => {
      if (user) {
        // const {
        //   data: { data },
        // } = await getUserDetailsById({
        //   queryKey: [null, user.userId],
        // });
        if (
          user.userRole === UserRoles.TUTOR &&
          user?.status !== UserStatus.ACTIVE &&
          user.createdBy !== UserCreatedBy.ADMIN &&
          user?.status !== UserStatus.COMMITMENT_END &&
          user?.status !== UserStatus.PREEMPTIVE_END
        ) {
          setRedirectToOnBoarding(true);
          return;
        }

        if (
          user?.userRole === UserRoles.PARENT &&
          user?.status !== UserStatus.ACTIVE
        ) {
          setRedirectToOnBoarding(true);
          return;
        }
      }
    };

    checkRedirects();
  }, [user]);
  //@ts-ignore
  const customise = useSelector((state) => state.customise);

  return (
    <Layout className="hp-app-layout">
      {redirectToOnBoarding ? (
        <></>
      ) : (
        <Sidebar visible={visible} setVisible={setVisible} />
      )}
      <Layout className="hp-bg-black-20 hp-bg-color-dark-90 hp-position-relative">
        <MenuHeader setVisible={setVisible} />

        <Content className="hp-content-main">
          <Row justify="center">
            {customise.contentWidth === "full" && (
              <Col xxl={24} xl={24} span={24}>
                {children}
              </Col>
            )}

            {customise.contentWidth === "boxed" && (
              <Col className="hp-w-100" style={{ maxWidth: 936 }}>
                {children}
              </Col>
            )}
          </Row>
        </Content>
      </Layout>
      <ScrollTop />
    </Layout>
  );
}
